import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useFetchEvents, categorizeEvents } from './eventUtils';
import './main.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Clock from './Clock';


function EventsDisplay() {
    const { events, loading, error } = useFetchEvents();
    const [eventsNow, setEventsNow] = useState([]);
    const [eventsLater, setEventsLater] = useState([]);
    const [upcoming, setUpcoming] = useState({});
    const [loggedDates, setLoggedDates] = useState({});
    const upcomingEventRefs = useRef({});
    const [fadeStates, setFadeStates] = useState({});
    const laterEventRefs = useRef({});
    const footerRef = useRef(null);
    const noEventsLaterRef = useRef(null);

    const getFadeClassName = useCallback((date, isTitle = false, isLaterEventTitle = false) => {
        let fadeState = fadeStates[date];
        if (isTitle) {
            // For title, pick the first date's fade state
            const firstUpcomingDate = Object.keys(upcoming)[0];
            fadeState = fadeStates[firstUpcomingDate];
        }
        if (isLaterEventTitle) {
            // If no events later, use the fade state of 'noEventsLater'
            fadeState = eventsLater.length === 0 ? fadeStates['noEventsLater'] : fadeStates[eventsLater[0]?.id || 'visible'];
        }

        switch (fadeState) {
            case 'hidden': return 'hiddenEvent';
            case 'fadeIn': return 'fadeInEvent';
            default: return '';
        }
    }, [fadeStates, upcoming, eventsLater]);

    const updateFadeState = useCallback((date, currentFadeState) => {
        if (currentFadeState === 'fading' || currentFadeState === 'fadeIn') {
            setTimeout(() => {
                setFadeStates(prev => ({
                    ...prev,
                    [date]: currentFadeState === 'fading' ? 'hidden' : 'visible'
                }));
            }, 500);
        }
    }, [setFadeStates]);


    const updateVisibility = useCallback(() => {
        const footerTop = footerRef.current ? footerRef.current.getBoundingClientRect().top : window.innerHeight;

        let newlyVisibleDates = [];
        Object.entries(upcomingEventRefs.current).forEach(([date, ref]) => {
            const elem = ref.current;
            if (elem) {
                const { bottom } = elem.getBoundingClientRect();
                const isCut = bottom > footerTop;
                const wasCut = loggedDates[date];
                const fadeState = fadeStates[date] || 'visible';

                if (wasCut && !isCut) {
                    console.log(`${date} is no longer being cut`);
                    newlyVisibleDates.push(date);
                    setFadeStates(prev => ({ ...prev, [date]: 'fadeIn' }));
                } else if (!wasCut && isCut) {
                    console.log(`${date} is being cut`);
                    setLoggedDates(prevDates => ({ ...prevDates, [date]: true }));
                    setFadeStates(prev => ({ ...prev, [date]: 'fading' }));
                }
                updateFadeState(date, fadeState);
            }
        });

        Object.entries(laterEventRefs.current).forEach(([eventId, ref]) => {
            const elem = ref.current;
            if (elem) {
                const { bottom } = elem.getBoundingClientRect();
                const isCut = bottom > footerTop;
                const fadeState = fadeStates[eventId] || 'visible';

                if (isCut && fadeState !== 'fading' && fadeState !== 'hidden') {
                    console.log(`${eventId} is being cut`);
                    setFadeStates(prev => ({ ...prev, [eventId]: 'fading' }));
                } else if (!isCut && (fadeState === 'fading' || fadeState === 'hidden')) {
                    console.log(`${eventId} is no longer being cut`);
                    setFadeStates(prev => ({ ...prev, [eventId]: 'fadeIn' }));
                }

                updateFadeState(eventId, fadeState);
            }
        });

        if (newlyVisibleDates.length > 0) {
            setLoggedDates(prevDates => {
                let updatedDates = { ...prevDates };
                newlyVisibleDates.forEach(date => {
                    delete updatedDates[date];
                });
                return updatedDates;
            });
        }

        const firstUpcomingDate = Object.keys(upcomingEventRefs.current)[0];
        if (firstUpcomingDate) {
            const fadeState = fadeStates[firstUpcomingDate] || 'visible';
            updateFadeState(firstUpcomingDate, fadeState);
        }

        // Add logic for the "No events happening later today" message
        if (noEventsLaterRef.current) {
            const elem = noEventsLaterRef.current;
            const { bottom } = elem.getBoundingClientRect();
            const isCut = bottom > footerTop;
            const fadeState = fadeStates['noEventsLater'] || 'visible';

            if (isCut && fadeState !== 'fading' && fadeState !== 'hidden') {
                setFadeStates(prev => ({ ...prev, noEventsLater: 'fading' }));
            } else if (!isCut && (fadeState === 'fading' || fadeState === 'hidden')) {
                setFadeStates(prev => ({ ...prev, noEventsLater: 'fadeIn' }));
            }

            updateFadeState('noEventsLater', fadeState);
        }

    }, [loggedDates, fadeStates, upcomingEventRefs, updateFadeState, footerRef, noEventsLaterRef]);

    useEffect(() => {
        function handleCategorizeEvents() {
            const categorized = categorizeEvents(events);
            setEventsNow(categorized.eventsNow);
            setEventsLater(categorized.eventsLater);
            setUpcoming(categorized.upcoming);
        }

        const intervalId = setInterval(handleCategorizeEvents, 60000);
        handleCategorizeEvents();

        const observer = new MutationObserver(updateVisibility);
        const container = document.querySelector('.eventsList');

        if (container) {
            observer.observe(container, {
                childList: true,
                subtree: true,
            });
        }

        window.addEventListener('resize', updateVisibility);
        updateVisibility();

        return () => {
            clearInterval(intervalId);
            observer.disconnect();
            window.removeEventListener('resize', updateVisibility);
        };
    }, [events, updateVisibility]);


    if (error) {
        return <div className="error">Error loading events: {error.message}</div>;
    }

    if (loading) {
        return (<div className="loading">Loading events ...</div>);
    }

    const titleFadeClass = getFadeClassName(null, true);
    const laterTitleFadeClass = getFadeClassName(null, false, true);

    const Footer = () => (
        <footer ref={footerRef} className="footer">
            <a href="https://dah.masjid.events/" target="_blank" rel="noopener noreferrer">
                dah.masjid.events
            </a>
        </footer>
    );

    return (
        <div>
            <div>
                <Clock />
                <div>
                    <h2>Events Happening Now</h2>
                    <div className="eventsList">
                        <TransitionGroup component="ul">
                            {eventsNow.map((event) => (
                                <CSSTransition
                                    key={event.id}
                                    classNames="event"
                                    timeout={5000}
                                    onEntered={updateVisibility}
                                    onExited={updateVisibility}
                                >
                                    <li className="eventContainer">
                                        <a href={`https://www.google.com/search?q=${encodeURIComponent(event.eventName + ' Dar Al-Hijrah')}`} target="_blank" rel="noopener noreferrer" className="eventDetailsLink">
                                            <div className="eventDetails">
                                                <strong>{event.eventName}</strong><br />
                                                Date: {event.eventDate}<br />
                                                Location: {event.eventLocation}<br />
                                                Recurrence: {event.eventRecurrence}<br />
                                            </div>
                                        </a>
                                        <div className="eventTime">
                                            {event.eventStartTime} - {event.eventEndTime}
                                        </div>
                                    </li>
                                </CSSTransition>
                            ))}
                            {eventsNow.length === 0 && (
                                <CSSTransition classNames="noevent" timeout={5000}>
                                    <li className="no-events">No events happening now.</li>
                                </CSSTransition>
                            )}
                        </TransitionGroup>
                    </div>
                </div>

                <div>
                    <h2 className={laterTitleFadeClass}>Events Happening Later Today</h2>
                    <div className="eventsList">
                        <TransitionGroup component="ul">
                            {eventsLater.map((event) => {
                                laterEventRefs.current[event.id] = laterEventRefs.current[event.id] || React.createRef();
                                return (
                                    <CSSTransition
                                        key={event.id}
                                        classNames="event"
                                        timeout={5000}
                                        onEntered={updateVisibility}
                                        onExited={updateVisibility}
                                    >
                                        <li className={`eventContainer ${getFadeClassName(event.id)}`} ref={laterEventRefs.current[event.id]}>
                                            <a href={`https://www.google.com/search?q=${encodeURIComponent(event.eventName + ' Dar Al-Hijrah')}`} target="_blank" rel="noopener noreferrer" className="eventDetailsLink">
                                                <div className="eventDetails">
                                                    <strong>{event.eventName}</strong><br />
                                                    Date: {event.eventDate}<br />
                                                    Location: {event.eventLocation}<br />
                                                    Recurrence: {event.eventRecurrence}<br />
                                                </div>
                                            </a>
                                            <div className="eventTime">
                                                {event.eventStartTime} - {event.eventEndTime}
                                            </div>
                                        </li>
                                    </CSSTransition>
                                );
                            })}
                            {eventsLater.length === 0 && (
                                <CSSTransition
                                    classNames="noevent"
                                    timeout={5000}
                                    onEntered={updateVisibility}
                                    onExited={updateVisibility}
                                >
                                    <li className={`no-events ${getFadeClassName('noEventsLater')}`} ref={noEventsLaterRef}>
                                        No events happening later today.
                                    </li>
                                </CSSTransition>
                            )}
                        </TransitionGroup>
                    </div>
                </div>

                <h2 className={titleFadeClass}>Upcoming Events</h2>
                {Object.entries(upcoming).map(([formattedDate, eventsForDate]) => {
                    upcomingEventRefs.current[formattedDate] = upcomingEventRefs.current[formattedDate] || React.createRef();
                    return (

                        <div key={formattedDate} ref={upcomingEventRefs.current[formattedDate]}
                            className={getFadeClassName(formattedDate)}>
                            <div className="eventDateGroup">{formattedDate} </div>
                            <div className="afterSpacer">
                                {eventsForDate.length === 0 ? (
                                    <li className="noupcoming-events">No events on this day.</li>
                                ) : (
                                    <TransitionGroup>
                                        {eventsForDate.map((event) => {
                                            return (
                                                <CSSTransition
                                                    key={event.id}
                                                    classNames="upcomingevent"
                                                    timeout={3000}
                                                    onEntered={updateVisibility}
                                                    onExited={updateVisibility}>
                                                    <li key={event.id} className="groupedEventContainer">
                                                        <a href={`https://www.google.com/search?q=${encodeURIComponent(event.eventName + ' Dar Al-Hijrah')}`} target="_blank" rel="noopener noreferrer" className="eventDetailsLink">
                                                            <div className="eventDetails">
                                                                <strong>{event.eventName}</strong> - {event.eventRecurrence} - {event.eventLocation}<br />
                                                            </div>
                                                        </a>
                                                        <div className="eventTime">
                                                            {event.eventStartTime.replace(/ /g, '\u00a0')} - {event.eventEndTime.replace(/ /g, '\u00a0')}
                                                        </div>
                                                    </li>
                                                </CSSTransition>
                                            );
                                        })}
                                    </TransitionGroup>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            <Footer />
        </div>
    );
}

export default EventsDisplay;