import { startOfDay, isToday, isFuture, compareAsc, format, isAfter, addDays, eachDayOfInterval } from 'date-fns';
import { useState, useEffect, useRef } from 'react';

export async function fetchEventsFromAPI() {
  try {
    // const response = await fetch('https://script.google.com/macros/s/AKfycbwhuBypU0Zg1yaj4AVNrPSUSdFowqzCtYsmI2MLWfw-asvzzo8pynJSmofp2RKbUVI1aw/exec');
    const response = await fetch('https://script.google.com/macros/s/AKfycbxYHcHnmvyx7ZeL7TFnGnOB5AoJeJnJQIIucxksMJcHbmI2MgI_pm8-PknxeWdFABQl6A/exec');
    return response.json();
  } catch (error) {
    console.error("Error fetching events:", error);
    throw error;
  }
}

export function parseRecurrenceRule(rule) {
  var components = rule.split(';');
  var frequency = '';
  var byDay = '';
  var interval = '';
  var count = '';

  components.forEach(function (component) {
    if (component.includes('FREQ')) {
      frequency = component.split('=')[1];
    } else if (component.includes('BYDAY')) {
      byDay = component.split('=')[1];
    } else if (component.includes('INTERVAL')) {
      interval = component.split('=')[1];
    } else if (component.includes('COUNT')) {
      count = component.split('=')[1];
    }
  });

  var freqMap = {
    'DAILY': 'Daily',
    'WEEKLY': 'Weekly',
    'MONTHLY': 'Monthly',
    'YEARLY': 'Yearly'
  };

  var dayMap = {
    'MO': 'Mon',
    'TU': 'Tue',
    'WE': 'Wed',
    'TH': 'Thu',
    'FR': 'Fri',
    'SA': 'Sat',
    'SU': 'Sun'
  };

  var ordinalMap = {
    '1': '1st',
    '2': '2nd',
    '3': '3rd',
    '4': '4th',
    '5': '5th',
    '-1': 'last'
  };

  var description = freqMap[frequency] || frequency;

  if (interval && interval !== '1') {
    if (frequency === 'WEEKLY' && interval === '2') {
      description = `Every 2 weeks`;
    } else {
      const freqLower = frequency.toLowerCase();
      const pluralFrequency = freqLower.endsWith('y') ? `${freqLower.substring(0, freqLower.length - 1)}ies` : `${freqLower}s`;
      description += ` every ${interval} ${pluralFrequency}`;
    }
  }

  if (byDay) {
    const orderedDays = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']; 
    var days = byDay.split(',').sort((a, b) => {
      return orderedDays.indexOf(a) - orderedDays.indexOf(b);
    }).map(function (day) {
      if (day.length > 2) {
        var ordinal = ordinalMap[day.substring(0, day.length - 2)];
        var weekday = dayMap[day.substring(day.length - 2)];
        return ordinal + ' ' + weekday;
      } else {
        return dayMap[day] || day;
      }
    }).join(', ');
    description += ' on ' + days;
  }

  if (count) {
    description += ', ' + count + ' times';
  }

  return description;
}

function customFormatTime(date) {
  const hours = date.getHours() % 12 || 12;
  const minutes = date.getMinutes();
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
  return `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
}

export function formatEvents(events) {
  const recurrenceCache = {};

  const now = new Date();
  const nonMasterEvents = events.filter(event => {
    return event.start.dateTime && event.end.dateTime && !(event.recurrence && !event.originalStartTime);
  });

  return nonMasterEvents.map(event => {
    const eventName = event.summary;
    const eventStartDateTime = new Date(event.start.dateTime || event.start.date);
    const eventEndDateTime = new Date(event.end.dateTime || event.end.date);
    const eventDate = format(eventStartDateTime, 'MMM dd, yyyy');
    const eventStartTime = customFormatTime(eventStartDateTime);
    const eventEndTime = customFormatTime(eventEndDateTime);
    const eventLocation = event.location || "N/A";
    let eventRecurrence = "Once";

    if (event.recurrence) {
      if (recurrenceCache[event.recurrence[0]]) {
        eventRecurrence = recurrenceCache[event.recurrence[0]];
      } else {
        eventRecurrence = parseRecurrenceRule(event.recurrence[0]);
        recurrenceCache[event.recurrence[0]] = eventRecurrence;
      }
    }
    else if (event.recurringEventId) {
      const mainEvent = events.find(e => e.id === event.recurringEventId && e.recurrence);
      if (mainEvent) {
        if (recurrenceCache[mainEvent.recurrence[0]]) {
          eventRecurrence = recurrenceCache[mainEvent.recurrence[0]];
        } else {
          eventRecurrence = parseRecurrenceRule(mainEvent.recurrence[0]);
          recurrenceCache[mainEvent.recurrence[0]] = eventRecurrence;
        }
      }
    }

    return {
      id: event.id,
      eventName,
      eventDate,
      eventStartTime,
      eventEndTime,
      eventRecurrence,
      eventLocation
    };
  })

    .filter(formattedEvent => {
      const eventStartDateTime = new Date(formattedEvent.eventDate + ' ' + formattedEvent.eventStartTime);
      const eventEndDateTime = new Date(formattedEvent.eventDate + ' ' + formattedEvent.eventEndTime);
      return (eventStartDateTime <= now && eventEndDateTime >= now) || eventStartDateTime >= now;
    })
    .filter(formattedEvent => {
      return !(formattedEvent.eventStartTime === '12:00 AM' && formattedEvent.eventEndTime === '12:00 AM' && formattedEvent.eventRecurrence !== 'Once');
    });
}

export const useFetchEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isFirstLoad = useRef(true); // Use useRef to track the first load

  useEffect(() => {
    const fetchEvents = async () => {
      if (isFirstLoad.current) {
        setLoading(true);
        isFirstLoad.current = false; // Set the flag to false after the first load
      }

      try {
        const data = await fetchEventsFromAPI();
        setEvents(formatEvents(data));
      } catch (error) {
        console.error("Error fetching events:", error);
        setError(error);
      } finally {
        if (loading) {
          setLoading(false);
        }
      }
    };

    fetchEvents();
    const intervalId = setInterval(fetchEvents, 15 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [loading]);

  return { events, loading, error };
};

export function categorizeEvents(events) {
  const now = new Date();
  const nowPlus30Seconds = new Date(now.getTime() + 30 * 1000);

  let eventsNowTemp = [];
  let eventsLaterTemp = [];
  let upcomingTemp = [];

  events.forEach(event => {
      const eventStartDateTime = new Date(event.eventDate + ' ' + event.eventStartTime);
      const eventEndDateTime = new Date(event.eventDate + ' ' + event.eventEndTime);

      if (eventStartDateTime <= nowPlus30Seconds && eventEndDateTime >= now) {
          eventsNowTemp.push(event);
      } else if (eventStartDateTime > nowPlus30Seconds && isToday(eventStartDateTime)) {
          eventsLaterTemp.push(event);
      } else if (isFuture(eventStartDateTime)) {
          upcomingTemp.push(event);
      }
  });

  // Sort upcoming events by date
  upcomingTemp.sort((a, b) => compareAsc(new Date(a.eventDate), new Date(b.eventDate)));

  // Group upcoming events by date
  const groupedUpcomingEvents = upcomingTemp.reduce((acc, event) => {
      const date = format(startOfDay(new Date(event.eventDate)), 'MMM dd, yyyy');
      (acc[date] = acc[date] || []).push(event);
      return acc;
  }, {});

  // Get range of dates for upcoming events
  let endDay = upcomingTemp.length > 0 ? new Date(upcomingTemp[upcomingTemp.length - 1].eventDate) : now;
  endDay = isAfter(addDays(now, 8), endDay) ? addDays(now, 8) : endDay;

  eachDayOfInterval({ start: addDays(now, 1), end: endDay }).forEach(date => {
      const formattedDate = format(date, 'MMM dd, yyyy');
      groupedUpcomingEvents[formattedDate] = groupedUpcomingEvents[formattedDate] || [];
  });

  // Sort and group upcoming events by date
  const sortedGroupedUpcomingEvents = Object.entries(groupedUpcomingEvents)
      .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
      .reduce((acc, [date, events]) => {
          const formattedDate = format(new Date(date), 'EEEE MMM dd, yyyy');
          acc[formattedDate] = events;
          return acc;
      }, {});

  return {
      eventsNow: eventsNowTemp,
      eventsLater: eventsLaterTemp,
      upcoming: sortedGroupedUpcomingEvents
  };
}