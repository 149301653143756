
import React, { useState, useEffect } from 'react';

function Clock() {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    return <div className="clockStyle">{time.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}</div>;
}

export default Clock;
